import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js';
import LayoutFunnel from "../components/layouts/layoutFunnel.js"

import withLocation from "../utils/withLocation"
import portrait from '../images/portraitOlivierL.jpg'
import axios from 'axios'
import Seo from '../components/Seo/';

import {
    Box,
    Button,
    Divider,
    Flex,
    Text,
    Heading,
    FormHelperText,
    Input,
    Image,
    List,
    ListItem,
    Stack,
    SimpleGrid,

    FormControl,
    FormLabel,
    Grid,
    Spinner,
} from '@chakra-ui/react'

import { FormattedMessage, useIntl } from 'react-intl';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const contentful = require("contentful");

//const translations = useIntl()['messages']

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
});

const siteConfig = require('../../config')

const title = {
    "fr": "Vous appréciez le service, soutenez le, Capitaine"
    ,
    "en": "I appeal to your generosity, Captain."
}
const description = {
    "fr": "Si vous souhaitez faire un petit don pour le fonctionnement du service, c'est ici."
    ,
    "en": "If you wish to make a small donation for the operation of the service, it is here."
};

const PremiumAdSubscription = (props) => {


    const stripePromise = loadStripe(process.env.GATSBY_STRIPE_CLIENT_KEY);
    const [ad, setAd] = useState(false)
    const [model, setModel] = useState(false)
    const [user, setUser] = useState(false)
    const [customPrice, setCustomPrice] = useState(false)
    const [isIniting, setIsIniting] = useState(false)

    useEffect(() => {
        if (!ad && props.search.ad) {
            axios.get(`${process.env.GATSBY_API_URL}/annonce/${props.search.ad}`)
                .then(function (response) {
                    setAd(response.data)
                    getModelDetails(response.data.refModel)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    });


    const getModelDetails = (modelID) => {
        client.getEntry(modelID, { locale: process.env.GATSBY_LANG }).then(function (entry) {
            console.log('entry.fields', entry.fields)
            setModel(entry.fields);
        })

    }

    // const handleGoStripe = async (stripeProduct, amount) => {
    //     const email = ad.user  ? { customerEmail: ad.user.email } : null
    //     console.log( stripeProduct )
    //     console.log( ad.user )
    //     const stripe = await stripePromise;
    //     const { error } = await stripe.redirectToCheckout({
    //         mode: 'payment',
    //         lineItems: [{ price: stripeProduct, quantity:1 }],
    //         successUrl: `${siteConfig.domains[ process.env.GATSBY_LANG ]}${siteConfig.links.donationThankyou[ process.env.GATSBY_LANG ]}?session_id={CHECKOUT_SESSION_ID}&adID=${ad._id}&amount=${amount}`,
    //         cancelUrl: `${siteConfig.domains[ process.env.GATSBY_LANG ]}${props.pageContext.slug}?ad=${props.search.ad}`,
    //         ...email
    //     })
    //     if( error ){
    //         console.log( error )
    //     }
    // }

    const handleGoStripe = async (amount) => {
        const stripe = await stripePromise;
        setIsIniting(true)
        axios.post(
            `${process.env.GATSBY_API_URL}/payment/init`,
            {
                amount: amount * 100,
                user: ad.user,
                ad: ad,
                successUrl: `${siteConfig.domains[process.env.GATSBY_LANG]}${siteConfig.links.donationThankyou[process.env.GATSBY_LANG]}?session_id={CHECKOUT_SESSION_ID}&adID&amount=${amount}`,
                cancelUrl: `${siteConfig.domains[process.env.GATSBY_LANG]}${props.pageContext.slug}?ad=${props.search.ad}`,
                context: 'ad-creation'
            }
        ).then(async (response) => {
            const result = await stripe.redirectToCheckout({
                sessionId: response.data.id,
            });
        })
    }

    const products = [
        {
            'name': 'Don Wanaboat.fr',
            'price': 3,
            'legend': <FormattedMessage id="donation.price.5.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_1,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 6,
            'legend': <FormattedMessage id="donation.price.6.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_2,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 9,
            'legend': <FormattedMessage id="donation.price.7.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_3,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 14,
            'legend': <FormattedMessage id="donation.price.8.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_3,
            'color': 'blue.brand'
        }
    ]
    const PriceCompareToAdPrice = (productPrice) => {
        const adPrice = ad.price
        const percent = productPrice / adPrice * 100
        return (Math.round((percent + Number.EPSILON) * 100) / 100)
    }

    const handleRefuse = () => {
        navigate('..')
    }

    return (
        <LayoutFunnel>
            <Seo
                title={title[process.env.GATSBY_LANG]}
                description={description[process.env.GATSBY_LANG]}
                slug={props.pageContext.slug}
            />
            <Flex
                justifyContent="center"
                alignItems="center"
                minH='100vh'
            >
                <Stack
                    maxW='740px'
                    spacing={{ base: '1.5rem', lg: '2rem' }}
                    px='1rem'
                    py={{ base: '2rem', lg: '4rem' }}
                    background='white'
                >
                    <Heading
                        as="h1"
                        // fontSize="42px"
                        fontSize={{ base: '3xl', lg: '4xl' }}
                        color="blue.brand"
                    >
                        <FormattedMessage id='donation.appreciate' />&nbsp;?
                    </Heading>
                    <Image
                        src={portrait}
                        alt="Le Capitaine de Wanaboat"
                    />
                    <Text
                        color="gray.700"
                        fontSize="18px"
                    >
                        <FormattedMessage id='donation.notice.3' />
                        &nbsp;:
                        <strong> <FormattedMessage id='donation.notice.4' />&nbsp;?</strong>
                    </Text>

                    <Box>

                        {!isIniting ?
                            <Stack
                                spacing='1.5rem'
                            >
                                <Box>
                                    <Text>
                                        <Text
                                            as='span'
                                            color='white'
                                            bg='blue.800'
                                            borderBottom='solid 1px'
                                            borderBottomColor='blue.700'
                                        >
                                            Pour vous remercier, je donnerai quelques supers pouvoirs à votre annonce&nbsp;: meilleure visibilité, publication sur la page Facebook de Wanaboat et remontée en tête de liste toutes les semaines.
                                        </Text>
                                    </Text>
                                </Box>
                                <SimpleGrid columns={{ base: 1, lg: 4 }} gap="1rem">
                                    {products.map((product, index) =>
                                        <div
                                            key={`${product.name}-${index}`}
                                        >
                                            <Box
                                                border='solid 2px'
                                                borderColor='blue.brand'
                                                boxShadow='md'
                                                p={{ base: '.5rem', lg: '1rem' }}
                                                minH={{ base: 'auto', lg: '135px' }}
                                                display='block'
                                                textAlign='center'
                                                borderRadius='lg'
                                                cursor='pointer'
                                                transition='transform 250ms ease'
                                                onClick={() => handleGoStripe(product.price)}
                                                _hover={{
                                                    transform: 'scale3d(1.05,1.05,1.05)',
                                                    backgroundColor: 'blue.50'
                                                }}
                                            >
                                                <Text color="blue.brand" fontWeight="bold" fontSize="24px">
                                                    {product.price}€
                                                </Text>
                                                <Text fontSize={{ base: '12px', lg: '14px' }}>
                                                    {product.legend}
                                                </Text>
                                            </Box>
                                            <Box>
                                                <Text textAlign='center' color='green.600' mt='.75rem' fontWeight='bold' fontSize={{ base: '10px', lg: '12px' }}>
                                                    <Text
                                                        as="span"
                                                        background="linear-gradient(270deg,rgba(255,255,255,0.48) 0%,rgba(255,255,255,0.221538) 53.85%,rgba(255,255,255,0) 100%),rgba(253,255,148,0.69)"
                                                    >
                                                        {PriceCompareToAdPrice(product.price)}%
                                                    </Text>&nbsp;
                                                    <FormattedMessage id="donation.of.the.price" />
                                                    <br />
                                                    {model ?
                                                        <>
                                                            <FormattedMessage id="donation.of.your" /> {model.name}
                                                        </>
                                                        : null}

                                                </Text>
                                            </Box>
                                        </div>
                                    )}
                                </SimpleGrid>
                                <Divider />
                                <Box
                                    as='form'
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        handleGoStripe(customPrice)
                                    }}
                                >
                                    <FormControl>
                                        <FormLabel><FormattedMessage id="donation.custom.notice" />&nbsp;:</FormLabel>
                                        <Grid
                                            gap='1rem'
                                            templateColumns={{ base: '100%', lg: '25% 1rem' }}
                                        >
                                            <Box>
                                                <Input
                                                    type="number"
                                                    min={1}
                                                    value={customPrice ? customPrice : ''}
                                                    onChange={(e) => setCustomPrice(e.target.value)}
                                                    onBlur={(e) => setCustomPrice(e.target.value)}
                                                    placeholder='19€'
                                                />
                                            </Box>
                                            <Box>
                                                <Button
                                                    colorScheme='blue'
                                                    isDisabled={!customPrice > 0 ? true : false} type='submit'>
                                                    {!customPrice > 0 ?
                                                        'Saisissez le montant de votre choix'
                                                        : 'Accéder au paiement'
                                                    }
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <FormHelperText>
                                            {customPrice > 0 ?
                                                <FormattedMessage id="donation.custom.button.label" />
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                                <Divider />
                                <Box mt='1rem'>
                                    <Button
                                        color='blue.900'
                                        boxShadow='sm'
                                        fontWeight='normal'
                                        p='.25rem 0'
                                        fontSize={{ base: '12px', lg: '18px' }}
                                        borderBottom='solid 1px'
                                        borderBottomColor='gray.300'
                                        whiteSpace='pre-wrap'
                                        textAlign='left'
                                        _hover={{
                                            textDecoration: 'none',
                                            borderBottomColor: 'blue.brand'
                                        }}
                                        onClick={handleRefuse}
                                    >→ <FormattedMessage id="donation.refuse.button.label" />
                                    </Button>
                                </Box>
                            </Stack>
                            :
                            <Flex justify='center' border="dashed 1px" borderColor='gray.300' borderRadius='sm' p={10} color='blue.600'><Spinner size='sm' /></Flex>
                        }

                    </Box>


                    <Text>
                        <FormattedMessage id='donation.notice.1' />
                    </Text>
                    <Box
                        bg='yellow.100'
                        borderLeft='solid 3px'
                        borderLeftColor='yellow.200'
                        p={{ base: 2, lg: 4 }}
                        borderRadius={{ base: 0, lg: 5 }}
                    >
                        <Text
                            fontWeight='bold'
                        >
                            <FormattedMessage id='donation.will.be.used' />
                        </Text>
                        <List>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.pay.charges' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.upgrade.service' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.keep.service.without.advert' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.have.a.drink' />
                            </ListItem>
                        </List>
                    </Box>
                </Stack>
            </Flex>
        </LayoutFunnel>
    )
}

export default withLocation(PremiumAdSubscription)